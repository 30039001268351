import { Injectable } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { Utils } from 'axis-ui-generator';
import { Configuration } from 'src/app/shared/configuration';
import * as moment from 'moment';
import { FnolNordicsApiService } from '../../service/common-nordics-api-services';
import { landingPageLabels } from '../../constants/fnol-norway-travel.constant';
import { CacheService } from 'src/app/shared/services/cache.service';
import { element } from 'protractor';
import { AxisInputNumberPipe } from "axis-components";
@Injectable({
  providedIn: 'root'
})
export class DeclarationServiceNoAhService {

 
  languagecheck= window.location.pathname.split('/')[4];
  language: string;
  constructor(public axisPipe : AxisInputNumberPipe,private dataStoreService: DataStoreService, private config: Configuration,  private cacheService: CacheService, private nordicsApiService: FnolNordicsApiService) {

  }
  uiStructure;
  uiData;
  claimTypeDetails = { "tripDetails": [], "dammage": [], "baggageDelay": [], "theft": [], "rental": [], "medical": [], "cancel": [], "tripDelay": [], "claimAmount": [] };
  claimDetailsInfo;
  lossTypesData;
  mySettings51: any = {
    view_roundoff: false,
    thousands_separator: ".",
    decimal_separator: ",",
    fraction_size: "2",
    reg_exp: "^[\.0-9]*$",
    decimal: "",
    restrict: false
   };
  getData(name, type, stepName) {
    this.uiStructure = this.dataStoreService.get(stepName).uiStructure;
    this.uiData = this.dataStoreService.get(stepName).uiData;
     let language= window.location.pathname.split('/')[4];
    const checkBoxFlag = Utils.getFieldFromStructure(this.uiStructure, name);
    if (type == "label") {
      const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
      if(resultData?.type === "checkbox"){
          return {
            label:resultData?.heading,
            questionCd: resultData?.questionCd,
            heading:resultData?.heading
          }
      }
      return resultData;
    } else if(type === "rpa"){
      const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
      if(resultData?.type === "dropdown"){
      if(resultData.hasOwnProperty('isMultiSearchSelect')  ){
         return this.uiData[name].map(i=>i.id).join(',')
        } else if( !resultData.hasOwnProperty('isMultiSearchSelect')){
         return this.uiData[name]?.id
        }
      } else if(resultData?.type === "radio"){    
       
        return  this.uiData[name];
      } 
       else if(resultData?.type === "checkbox"){    
       
        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No' ) ? "Yes" : "No"
           
       }
       
      else if(resultData?.type === "text" ||  resultData?.type === "datepicker" ||resultData?.type === "timepicker" || resultData?.type === null || resultData?.type === "textarea") {
        return null
      }

    } else {
      let language= window.location.pathname.split('/')[4];
    //  if(language  === "en"){
      const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
      if(resultData?.type === "datepicker" &&  this.uiData[name] != 'Invalid date' &&  this.uiData[name] != null &&  this.uiData[name] != ''){
        return (this.uiData[name]!=null && this.uiData[name]!="") ? moment(new Date(this.uiData[name])).format('DD-MM-YYYY'):this.uiData[name];
      }else if(resultData?.type === "timepicker"){
       return (this.uiData[name]!=null && this.uiData[name]!="") &&this.uiData[name]?.length>5  ? moment(this.uiData[name]).format("HH:mm"):this.uiData[name];
      }else if(resultData?.type === "checkbox"){
        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No' ) ? $localize`:@@yesNor:Yes` : $localize`:@@noNor:No`
       }
              else if(resultData?.type === "dropdown"){
     
        if(resultData.hasOwnProperty('isMultiSearchSelect')  ){
          return this.uiData[name].map(i=>i.name).join(',')
         } else if( !resultData.hasOwnProperty('isMultiSearchSelect')){
          return this.uiData[name]?.name
         }
        
       } else if(resultData?.type === "radio"){    
        let language= window.location.pathname.split('/')[4]; 
         return language === 'en'? this.uiData[name]: Utils.getFieldFromStructure(this.uiStructure, name).options.filter(e => e.value===this.uiData[name])[0]?Utils.getFieldFromStructure(this.uiStructure, name).options.filter(e => e.value===this.uiData[name])[0]['label']:""
      }
      else{
        if(resultData  && resultData.type === "addressLookup" && typeof this.uiData[name] === 'object' ){
          return this.uiData[name]['viewModel'] ||  this.uiData[name]['name']
        } else{
          return this.uiData[name]
        }
       
      }

     }

    // } 

  }
  step1Name = 'fnol-claimant-info';
  step2Name = 'fnol-claimtype-details';
  step3Name = 'fnol-payment-details';

  getCreditCard() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let creditCardInfoDetails = []
    let creditCardInfo = ["chooseYourselfAccident","creditCardPrimary", "creditCard4"]

    creditCardInfo.forEach(res => {
      if(this.getData(res, "label", this.step1Name)['type'] ==="dropdown" ){
       creditCardInfoDetails.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd":this.getData(res, "rpa", this.step1Name),

          "answer": this.getData(res, "", this.step1Name).name
        })
      }
     else {
      creditCardInfoDetails.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd":  this.getData(res, "rpa", this.step1Name),
          "answer": this.getData(res, "", this.step1Name)
        })
        }
    })
  

    if (uiData['anotherInsuranceCreditCard'] === "Yes") {
      creditCardInfoDetails.push( {
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd":  this.getData("anotherInsuranceCreditCard", "rpa", this.step1Name),
        "answer": this.getData("anotherInsuranceCreditCard", "", this.step1Name),
        "additionalInfo": this.getCreditTravel() })

    }else{
      creditCardInfoDetails.push( {
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": this.getData("anotherInsuranceCreditCard", "rpa", this.step1Name),
        "answer":this.getData("anotherInsuranceCreditCard", "", this.step1Name)
    })
  }

   

    return creditCardInfoDetails;

  }

  getCreditTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "anotherInsuranceCreditCard-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      console.log(labelIndex + "label index ");
      const nonBagNoEMp = ["companyNameCredit", "policyNameCredit", "typePolicyCredit","policyCheckCredit"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + (i+1),
        "featureTypeCd": "additionalInsurance" + (i+1),
        "questionAnswer": travelArr
      })
    })
    
    return travelMainArr
  }

  getUnionInfo() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let unionDetails = this.dataStoreService.get('fnol-landing').uiStructure[0].value === "Personal Accident" || this.dataStoreService.get('fnol-landing').uiStructure[0].value=== "Accidental Death" || this.dataStoreService.get('fnol-landing').uiStructure[0].value === "Personulykke" || this.dataStoreService.get('fnol-landing').uiStructure[0].value=== "Dødsfall som følge av ulykke"? ["chooseYourselfAccident","creditCardAssociationUnion", "creditCard4AssociationUnion", "creditCardPolicyNumberUnion"]:["chooseYourselfCritical","creditCardAssociation","creditCard4AssociationUnionCritical","creditCardPolicyNumber"];
    //let unionDetails = ["chooseYourselfAccident","creditCardAssociationUnion", "creditCard4AssociationUnion", "creditCardPolicyNumberUnion",];
    let unionDetailsInfo = [];
    unionDetails.forEach(res => {
      unionDetailsInfo.push({
        "questionText": this.getData(res, "label", this.step1Name)['label'],
        "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
        "answerCd": this.getData(res, "rpa", this.step1Name),
        "answer": this.getData(res, "", this.step1Name)
      })
    })
  
  
    if (uiData['unionInsurance'] === "Yes") {
      unionDetailsInfo.push({
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": this.getData("unionInsurance", "rpa", this.step1Name),
        "answer": this.getData("unionInsurance", "", this.step1Name),
        "additionalInfo": this.getUnionTravel() })

    }else{
      unionDetailsInfo.push({
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": this.getData("unionInsurance", "rpa", this.step1Name),
        "answer": this.getData("unionInsurance", "", this.step1Name)
       })

    }
    return unionDetailsInfo;
  }

  getUnionTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "unionInsurance-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = ["companyNameUnion", "policyNameCreditUnion", "typePolicyUnion","policyCheckUnion"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + (i+1),
        "featureTypeCd": "additionalInsurance" + (i+1),
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }

  getMyself() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let myselfDetails = ["chooseYourselfAccident","policyNumberPurchased"]
    let myselfDetailsInfo = [];
    myselfDetails.forEach(res => {
      myselfDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answer": this.getData(res, "", this.step1Name),
          "answerCd":this.getData(res, "rpa", this.step1Name),
        })
    })
    
    if (uiData['purchasedInsurance'] === "Yes") {
      myselfDetailsInfo.push({
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": this.getData("purchasedInsurance", "rpa", this.step1Name),
        "answer":this.getData("purchasedInsurance", "", this.step1Name),
        "additionalInfo": this.getMyselfTravel()
      })
    }else{
      myselfDetailsInfo.push({
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd":  this.getData("purchasedInsurance", "rpa", this.step1Name),
        "answer": this.getData("purchasedInsurance", "", this.step1Name),
      })
    }

 
 
    return myselfDetailsInfo

  }

  getPersonal(){


    let gield=[]
  }

getMyselfTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "purchasedInsurance-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = ["companyNamePurchased", "policyNameCreditPurchased", "typePolicyCreditPurchased","policyCheckPurchased"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + (i+1),
        "featureTypeCd": "additionalInsurance" + (i+1),
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }

  getEmployer() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let employeeDetails = this.dataStoreService.get('fnol-landing').uiStructure[0].value === "Personal Accident" || this.dataStoreService.get('fnol-landing').uiStructure[0].value=== "Accidental Death" || this.dataStoreService.get('fnol-landing').uiStructure[0].value === "Personulykke" || this.dataStoreService.get('fnol-landing').uiStructure[0].value=== "Dødsfall som følge av ulykke"? ["chooseYourselfAccident","nameEmployer", "nameEmployee", "emailAddressEmployer", "creditCardEmployer"]:["chooseYourselfCritical","nameEmployerCritical","nameEmployeeCritical","emailAddressCritical","creditCardEmployerCritical"]
    let employerDetailsInfo = [];
    employeeDetails.forEach(res => {
      employerDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answer": this.getData(res, "", this.step1Name),
          "answerCd":this.getData(res, "rpa", this.step1Name),
        })
    })
    
    if (uiData['insuranceEmployer'] === "Yes") {
      employerDetailsInfo.push({
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd":  this.getData("insuranceEmployer", "rpa", this.step1Name),
        "answer": this.getData("insuranceEmployer", "", this.step1Name),
        "additionalInfo": this.getEmployerTravel() })
    } else{
      employerDetailsInfo.push({
        "questionText": $localize`:@@doYouAccident:Do you have another insurance that might cover the same incident?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd":  this.getData("insuranceEmployer", "rpa", this.step1Name),
        "answer": this.getData("insuranceEmployer", "", this.step1Name),
       })
    }
    return employerDetailsInfo
  }

  getEmployerTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "insuranceEmployer-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      let nonBagNoEMp = ["companyNameEmployer", "policyNameCreditEmployer","typePolicyCreditEmployer","policyCheckEmployer"]
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + (i+1),
        "featureTypeCd": "additionalInsurance" + (i+1),
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }

  getMedicalExpenses(){
    let medicalExpensesData = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "medicalExpenses-Yes").fields
    let medicalExpensesDataArr = []
    medicalExpensesData.forEach((data, i) => {
      let medicalArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      let medicalExpense = ["descExpense", "expenseAmount"];
      medicalExpense.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step2Name)["type"] == "checkbox" ){
          medicalArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step2Name),
            "answer": this.getData(item + index, "", this.step2Name)
          });

        } else if(item === "expenseAmount"){
          medicalArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index , "",  this.step2Name)['phoneNumber']?
            this.getData(item + index , "",  this.step2Name)['phoneCode'].hasOwnProperty('label')?
            this.getData(item + index , "",  this.step2Name)['phoneCode']['label']+ " "  + this.axisPipe.transform(this.getData(item + index , "", this.step2Name)['phoneNumber'].toString(),this.mySettings51):
            this.getData(item + index , "",  this.step2Name)['phoneCode'] + " "  + this.axisPipe.transform(this.getData(item + index , "", this.step2Name)['phoneNumber'].toString(),this.mySettings51)
             : null
          });
          
        } else {
          medicalArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step2Name),
            "answer": this.getData(item + index, "", this.step2Name)
          });
        }

      });
      medicalExpensesDataArr.push({
        "featureTypeText": $localize`:@@expense:Expense #` + (i+1),
        "featureTypeCd": "expenseNo" + (i+1),
        "questionAnswer": medicalArr
      })
    })
    return medicalExpensesDataArr
  }
  getInsurance(){
    let insuranceData = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "accidentClaim-Yes").fields
    let insuranceDataArr = []
    insuranceData.forEach((data, i) => {
      let insuranceArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      let insurance = ["numberOfClaims", "insuranceCompanyName"];
      insurance.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step2Name)["type"] == "checkbox" ){
          insuranceArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step2Name),
            "answer": this.getData(item + index, "", this.step2Name)
          });

        }else{
          insuranceArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step2Name),
            "answer": this.getData(item + index, "", this.step2Name)
          });
        }

      });
      insuranceDataArr.push({
        "featureTypeText": $localize`:@@addInsurance1:Insurance Company #` + (i+1),
        "featureTypeCd": "accidentClaims" + (i+1),
        "questionAnswer": insuranceArr
      })
    })
    return insuranceDataArr
  }
  getDueHospitalized(){
    let dueHospitalizedData = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "dueHospitalized-Yes").fields
    let dueHospitalizedDataArr = []
    dueHospitalizedData.forEach((data, i) => {
      let dueHospitalizedArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      let dueHospitalized = ["hospitalName","treatmentAdmissionDate", "treatmentDischargeDate"];
      dueHospitalized.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step2Name)["type"] == "checkbox" ){
          dueHospitalizedArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step2Name),
            "answer": this.getData(item + index, "", this.step2Name)
          });

        }else{
          dueHospitalizedArr.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step2Name),
            "answer": this.getData(item + index, "", this.step2Name)
          });
        }

      });
      dueHospitalizedDataArr.push({
        "featureTypeText": $localize`:@@addHospital1:Hospitalization Period #` + (i+1),
        "featureTypeCd": "hospitalNo" + (i+1),
        "questionAnswer": dueHospitalizedArr
      })
    })
    return dueHospitalizedDataArr
  }
 
  getPersonalAccident() {
    let uiData = this.dataStoreService.get(this.step2Name).uiData;
    let personalAccidentDetails = ["dateOfAccident","timeOfAccident","countryOfAccident","leisureBusiness","accidentDescriptionCause","medicalTreatmentDate","AccidentMedicalFacility","accidentDiagnosis","diagnosisOthersInjury","dateOfDiagnosisPersonal"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetails.forEach(res => {
      personalAccidentDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step2Name)['label'],
          "questionCd": this.getData(res, "label", this.step2Name)['questionCd'],
          "answer": this.getData(res, "", this.step2Name),
          "answerCd": this.getData(res, "rpa", this.step2Name),
        })
    })
    if (uiData['accidentClaim'] === "Yes") {
      personalAccidentDetailsInfo.push({
        "questionText": this.getData('accidentClaim', "label", this.step2Name)['label'],
        "questionCd": this.getData('accidentClaim', "label", this.step2Name)['questionCd'],
        "answer": this.getData('accidentClaim', "", this.step2Name),
        "answerCd":this.getData('accidentClaim', "rpa", this.step2Name),
        "additionText": $localize`:@@addInsurance:Insurance Company`,
        "additionalInfo": this.getInsurance()
      })
    } else{
      personalAccidentDetailsInfo.push({
        "questionText": this.getData('accidentClaim', "label", this.step2Name)['label'],
        "questionCd": this.getData('accidentClaim', "label", this.step2Name)['questionCd'],
        "answerCd": this.getData('accidentClaim', "rpa", this.step2Name),
        "answer": this.getData('accidentClaim', "", this.step2Name),
       })
    }
  
    if (uiData['dueHospitalized'] === "Yes") {
      personalAccidentDetailsInfo.push(
          {
            "questionText": this.getData('dueHospitalized', "label", this.step2Name)['label'],
            "questionCd": this.getData('dueHospitalized', "label", this.step2Name)['questionCd'],
            "answer": this.getData('dueHospitalized', "", this.step2Name),
            "answerCd":this.getData('dueHospitalized', "rpa", this.step2Name),
            "additionText": $localize`:@@addHospital:Hospitalization Period`,
            "additionalInfo": this.getDueHospitalized()
          })
    } else{
      personalAccidentDetailsInfo.push({
        "questionText": this.getData('dueHospitalized', "label", this.step2Name)['label'],
        "questionCd": this.getData('dueHospitalized', "label", this.step2Name)['questionCd'],
        "answerCd": this.getData('dueHospitalized', "rpa", this.step2Name),
        "answer": this.getData('dueHospitalized', "", this.step2Name),
       })
    }
    if (uiData['medicalExpenses'] === "Yes") {

      personalAccidentDetailsInfo.push(
          {
            "questionText": this.getData('medicalExpenses', "label", this.step2Name)['label'],
            "questionCd": this.getData('medicalExpenses', "label", this.step2Name)['questionCd'],
            "answer": this.getData('medicalExpenses', "", this.step2Name),
            "answerCd": this.getData('medicalExpenses', "rpa", this.step2Name),
            "additionText": $localize`:@@addExpense:Expense`,
            "additionalInfo": this.getMedicalExpenses(),
          
      })
    } else{
      personalAccidentDetailsInfo.push({
        "questionText": this.getData('medicalExpenses', "label", this.step2Name)['label'],
        "questionCd": this.getData('medicalExpenses', "label", this.step2Name)['questionCd'],
        "answerCd": this.getData('medicalExpenses', "rpa", this.step2Name),
        "answer": this.getData('medicalExpenses', "", this.step2Name),
       })
    }
    let treatmentDetails=["continueTreatment", "personalAccidentTreatmentInformation"]
    treatmentDetails.forEach(res => {
      personalAccidentDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step2Name)['label'],
          "questionCd": this.getData(res, "label", this.step2Name)['questionCd'],
          "answer": this.getData(res, "", this.step2Name),
          "answerCd": this.getData(res, "rpa", this.step2Name),
        })
    })

    return personalAccidentDetailsInfo
    
  }
  
  getCriticalIllness() {
    let uiData = this.dataStoreService.get(this.step2Name).uiData;
    let criticalIllnessDetails = ["dateOfFirstSymptoms","dateOfMedicalTreatment", "IllnessDiagnosis", "diagnosisOthersIllness","diagnosisDate","InjuryMedicalFacility"];
    let criticalIllnessDetailsInfo = [];
    criticalIllnessDetails.forEach(res => {
      criticalIllnessDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step2Name)['label'],
          "questionCd": this.getData(res, "label", this.step2Name)['questionCd'],
          "answer": this.getData(res, "", this.step2Name),
          "answerCd":this.getData(res, "rpa", this.step2Name),
        })
    })
    let diagnosisDetails = ["similarDiagnosis"]
    
    if (uiData['similarDiagnosis'] === "Yes") {
      
    
      criticalIllnessDetailsInfo.push(
          
            {
              "questionText": this.getData('similarDiagnosis', "label", this.step2Name)['label'],
              "questionCd": this.getData('similarDiagnosis', "label", this.step2Name)['questionCd'],
              "answer": this.getData('similarDiagnosis', "", this.step2Name),
              "answerCd": this.getData('similarDiagnosis', "rpa", this.step2Name),      
              },
              {
              "questionText": this.getData("descOfPreviousDiagnosis", "label", this.step2Name)['label'],
              "questionCd": this.getData("descOfPreviousDiagnosis", "label", this.step2Name)['questionCd'],
              "answerCd": this.getData("descOfPreviousDiagnosis", "rpa", this.step2Name),
              "answer": this.getData("descOfPreviousDiagnosis", "", this.step2Name)
            })
          
    } else{
      criticalIllnessDetailsInfo.push({
        "questionText": this.getData('similarDiagnosis', "label", this.step2Name)['label'],
        "questionCd": this.getData('similarDiagnosis', "label", this.step2Name)['questionCd'],
        "answerCd":  this.getData('similarDiagnosis', "rpa", this.step2Name),
        "answer": this.getData('similarDiagnosis', "", this.step2Name),
       })
    }
   let additionalDetails =["criticalIllnessDiagnosisInformation"]
   additionalDetails.forEach(res => {
    criticalIllnessDetailsInfo.push(
      {
        "questionText": this.getData(res, "label", this.step2Name)['label'],
        "questionCd": this.getData(res, "label", this.step2Name)['questionCd'],
        "answer": this.getData(res, "", this.step2Name),
        "answerCd":this.getData(res, "rpa", this.step2Name),
      })
  })
    return criticalIllnessDetailsInfo
    
  }
  
  getAccidentalDeath() {
    let uiData = this.dataStoreService.get(this.step2Name).uiData;
    let accidentalDeathDetails = ["accidentIllnessDescription","dateOfPassing", "countryOfPassing"];
    let accidentalDeathDetailsInfo = [];
    accidentalDeathDetails.forEach(res => {
      accidentalDeathDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step2Name)['label'],
          "questionCd": this.getData(res, "label", this.step2Name)['questionCd'],
          "answer": this.getData(res, "", this.step2Name),
          "answerCd": this.getData(res, "rpa", this.step2Name),
        })
    })
    
    if (uiData['chronicIllness'] === "Yes") {
      
    
      accidentalDeathDetailsInfo.push(
          {
            "questionText": this.getData('chronicIllness', "label", this.step2Name)['label'],
            "questionCd": this.getData('chronicIllness', "label", this.step2Name)['questionCd'],
            "answer": this.getData('chronicIllness', "", this.step2Name),
            "answerCd": this.getData('chronicIllness', "rpa", this.step2Name),      
            },
            {
            "questionText": this.getData("typeOfIllness", "label", this.step2Name)['label'],
            "questionCd": this.getData("typeOfIllness", "label", this.step2Name)['questionCd'],
            "answerCd": this.getData("typeOfIllness", "rpa", this.step2Name),
            "answer": this.getData("typeOfIllness", "", this.step2Name)
          })
          
      
    } else{
      accidentalDeathDetailsInfo.push({
        "questionText": this.getData('chronicIllness', "label", this.step2Name)['label'],
        "questionCd": this.getData('chronicIllness', "label", this.step2Name)['questionCd'],
        "answerCd": this.getData('chronicIllness', "rpa", this.step2Name),
        "answer": this.getData('chronicIllness', "", this.step2Name),
       })
    }
    let additionalDetails =["accidentIllnessInformation"]
   additionalDetails.forEach(res => {
    accidentalDeathDetailsInfo.push(
      {
        "questionText": this.getData(res, "label", this.step2Name)['label'],
        "questionCd": this.getData(res, "label", this.step2Name)['questionCd'],
        "answer": this.getData(res, "", this.step2Name),
        "answerCd":this.getData(res, "rpa", this.step2Name),
      })
  })
    return accidentalDeathDetailsInfo
    
  }

 


  getPersonalObj(){
    let personalInfo = ["personalFirstName", "personalLastName", "personalEmail"];
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let personalDetailsInfo = [];
    personalInfo.forEach(res => {
      personalDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd": this.getData(res, "rpa", this.step1Name),

          "answer": this.getData(res, "", this.step1Name)
        })
    })

    personalDetailsInfo.push(
      {
        "questionText": $localize`:@@phoneNumberNor:Phone Number`,
        "questionCd":  "phoneNumber",
        "answerCd":null,
        "answer": this.getData("personalPhone", "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("personalPhone", "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("personalPhone", "", this.step1Name)['phoneNumber'] : this.getData("personalPhone", "", this.step1Name)['phoneCode'] + "-"  + this.getData("personalPhone", "", this.step1Name)['phoneNumber']
      })
      personalDetailsInfo.push(
        {
          "questionText": $localize`:@@iApprovemail:Do you approve to receive communication by email?`,
          "questionCd":  "emailCommunication",
          "answerCd": this.getData("personalCheck", "rpa", this.step1Name),

          "answer":  this.dataStoreService.get(this.step1Name).uiData['personalCheck'] === "Yes"? $localize`:@@yesNor:Yes`: $localize`:@@noNor:No`
        })

        return personalDetailsInfo
  }

  getPersonalContainerVal(){
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let accident = this.dataStoreService.get('fnol-landing').uiStructure[0].value;
    let SelectPersonArr=[]
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addPersonMain").fields
    travelInsurance.forEach((data, i) => {
      let personalDetailsInfo = [];

      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;  
      let claimType=  this.dataStoreService.get('fnol-landing').uiStructure[0].value  
      if(claimType === "Personal Accident" ||  claimType == "Critical Illness" || claimType === "Personulykke" ||  claimType == "Kritisk sykdom") {  
        personalDetailsInfo.push({
        "questionText": this.getData("selectPerson" + index, "label", this.step1Name)['label'],
        "questionCd": this.getData("selectPerson" + index, "label", this.step1Name)['questionCd'],
        "answerCd": this.getData("selectPerson" + index, "rpa", this.step1Name),
        "answer": this.getData("selectPerson" + index, "", this.step1Name)
         });
        }
      let type = []
      if (uiData["selectPerson" + index]?.id === "1") {
        type = ["nationalIdentity", "yourselfMailingAddress", "yourselfPostalCode", "yourselfCity", "yourselfCountry"]
      } else if (uiData["selectPerson" + index]?.id === "2") {
        //let someoneElseCheck=this.getData("someoneElseCheck" + index, "", this.step1Name);
        type = ["someoneElseRelationship", "someoneElseFirstName", "someoneElseLastName", "someoneElseNationalIdentity", "someoneElseCheck", "someoneElseMailingAddress", "someoneElsePostalCode", "someoneElseCity", "someoneElseCountry"];
        if(uiData["someoneElseRelationship" + index]?.id === "3"){
          type.splice(1, 0, "someoneElseOthers");
        }

      } 
      if (accident ==="Accidental Death" || accident ==="Dødsfall som følge av ulykke"){
        type = ["accidentalRelationship", "accidentalFirstName", "accidentalLastName", "accidentalNationalIdentity", "accidentalMailingAddress", "accidentalPostalCode", "accidentalCity", "accidentalCountry"];
        if(uiData["accidentalRelationship" + index]?.id === "3"){
          type.splice(1, 0, "othersAccident");
      }
    }
      type.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)['type']==="dropdown"){
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":  this.getData(item + index, "rpa", this.step1Name),
            "answer":  this.getData(item + index, "", this.step1Name).name?this.getData(item + index, "", this.step1Name).name:this.getData(item + index, "", this.step1Name)
          });
        }
        else if(item==="someoneElseCheck"){
          let someoneElseCheck=this.getData("someoneElseCheck" + index, "", this.step1Name);
          if(someoneElseCheck==="No" || someoneElseCheck==="Nei")
          {
            personalDetailsInfo.push({
              "questionText": this.getData("someoneElseEmail" + index, "label", this.step1Name)['label'],
              "questionCd": this.getData("someoneElseEmail" + index, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("someoneElseEmail" + index, "", this.step1Name)
            });

            let someoneElsePhone=this.getData("someoneElsePhone" + index, "", this.step1Name);
            if(someoneElsePhone['phoneNumber']!=="")
            {
              personalDetailsInfo.push(
              {
                "questionText": $localize`:@@phoneNumberNor:Phone Number`,
                "questionCd": "phoneNumber",
                "answerCd": null,
                "answer": this.getData("someoneElsePhone", "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("someoneElsePhone", "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("someoneElsePhone", "", this.step1Name)['phoneNumber'] : this.getData("someoneElsePhone", "", this.step1Name)['phoneCode'] + "-"  + this.getData("someoneElsePhone", "", this.step1Name)['phoneNumber']
              });
            }
            
          }
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }  
        
        else if(item ==="yourselfMailingAddress" || item ==="someoneElseMailingAddress" || item ==="employerMailingAddress" || item ==="accidentalMailingAddress"){

          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index+"val", "", this.step1Name)? this.getData(item + index+"val", "", this.step1Name): this.getData(item + index, "", this.step1Name).formatted_address?this.getData(item + index, "", this.step1Name).formatted_address:this.getData(item + index, "", this.step1Name)
          });


         } else {
          personalDetailsInfo.push({
            "questionText":((item + index).indexOf('accidentalNationalIdentity'+ index)>-1||(item + index).indexOf('nationalIdentity'+ index)>-1 ||((item + index).indexOf('someoneElseNationalIdentity'+ index)>-1) ||((item + index).indexOf('employerNationalIdentity'+ index)>-1) || ((item + index).indexOf('accidentalNationalIdentity'+ index)>-1) )? $localize`:@@nationIdent:National Identity Number`: this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd":((item + index).indexOf('accidentalNationalIdentity'+ index)>-1||(item + index).indexOf('nationalIdentity'+ index) >-1||((item + index).indexOf('someoneElseNationalIdentity'+ index)>-1) ||((item + index).indexOf('employerNationalIdentity'+ index)>-1) ||((item + index).indexOf('accidentalNationalIdentity'+ index)>-1) )? item :this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":null,
            "answer": ((item).indexOf('accidentalNationalIdentity'+ index)>-1|| (item + index).indexOf('nationalIdentity'+ index)>-1 ||((item + index).indexOf('someoneElseNationalIdentity'+ index)>-1) ||((item + index).indexOf('employerNationalIdentity'+ index)>-1) ||((item + index).indexOf('accidentalNationalIdentity'+ index)>-1) )?this.getMaksing(this.getData(item + index, "", this.step1Name)):this.getData(item + index, "", this.step1Name)
          });
        }

      });

      let headIndex = index != ""? index :1
     
      SelectPersonArr.push({
        "featureTypeText": "Person #1" ,
        "featureTypeCd": "person1" ,
        "questionAnswer":personalDetailsInfo
      })      
    })
    return SelectPersonArr
  }

  getPersonalDetailsInfo() {
    let personalDetailsArray = []

    let personalObj = {
      "featureTypeText": $localize`:@@personalDetailsNor:Personal Details`,
      "featureTypeCd": "claimant",
      "questionAnswer": this.getPersonalObj()
    }
    personalDetailsArray.push(personalObj)
    return personalDetailsArray.concat(this.getPersonalContainerVal());
  }

  getUploadDetails(){
    let obj=[]
    if (this.dataStoreService.get("fnol-upload-docs").uiData.customFileUpload){
  let fileList = this.dataStoreService.get("fnol-upload-docs").uiData.customFileUpload.uploads;

  fileList.forEach((element,i) => {
    if(this.language==='en'){
      obj.push(
        {
          "questionText": element.claimBenefitType,
          "questionCd": "document" + (i + 1),
          "answerCd": null,
          "answer": element.name
        }
  
      )
    }
    else{
    obj.push(
      {
        "questionText": element.displayDocType,
        "questionCd": "document" + (i + 1),
        "answerCd": null,
        "answer": element.name
      }

    )
    }
  });

  return obj;
  } else {
    return obj;
  }
}

getFeatureType(type){
  let claimType=this.dataStoreService.get('fnol-landing').uiStructure[0].value
  if(claimType === "Personal Accident" ||  claimType ===  "Personulykke"){

    return type === 'text'? $localize`:@@personalAccident:Personal Accident Details`:"accidentDetails"
  }  else if(claimType  === 'Critical Illness' ||  claimType ===  "Kritisk sykdom"){
    return type === 'text'? $localize`:@@criticalIllness:Critical Illness Details`:"criticalIllnessDetails"
  } else if(claimType === 'Accidental Death'||  claimType ===  "Dødsfall som følge av ulykke") {
    return type === 'text'? $localize`:@@accidentalDeath:Accidental Death Details`:"deathDetails"
  }
}
  getPayloadStep2() {
    let claimType=this.dataStoreService.get('fnol-landing').uiStructure[0].value
    if(claimType === "Personal Accident" ||  claimType ===  "Personulykke"){

      return this.getPersonalAccident();
    }  else if(claimType  === 'Critical Illness'||  claimType ===  "Kritisk sykdom" ){
      return this.getCriticalIllness()
    } else if(claimType === 'Accidental Death'||  claimType ===  "Dødsfall som følge av ulykke") {
      return this.getAccidentalDeath()
    }
  }

  getInsuranceInfo() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData
    let uiDetails = this.dataStoreService.get('fnol-landing').uiStructure[0].value === "Personal Accident" || this.dataStoreService.get('fnol-landing').uiStructure[0].value=== "Accidental Death" || this.dataStoreService.get('fnol-landing').uiStructure[0].value === "Personulykke" || this.dataStoreService.get('fnol-landing').uiStructure[0].value=== "Dødsfall som følge av ulykke"? uiData.chooseYourselfAccident: uiData.chooseYourselfCritical
    if (uiData.chooseYourselfAccident === "Credit Card") return this.getCreditCard()
    
    else if (uiDetails === "Union / Association") return this.getUnionInfo()
    else if (uiData.chooseYourselfAccident === "Purchased Myself") return this.getMyself()
    else if (uiDetails === "Employer") return this.getEmployer()
    
  }

  recursiveData(dataArr, uiData, result = []) {
    // var r = result;
    dataArr.forEach((field, i) => {
      let value = field;
      if (value.type === "text" || value.type === "radio" || value.type === "dropdown" || value.type === "datepicker" ||value.type === "custom" ||
       value.type === "checkbox" || value.type === "textarea" || value.type === "timepicker") {
        if(value.type === "datepicker"){
          this.uiData[value.name] = (this.uiData[value.name]!=null || this.uiData[value.name]!="") ? moment(this.uiData[value.name]).format('DD-MM-YYYY'):this.uiData[value.name];
        }
        result.push(value);


        // r.push(value);
      } else if (value.type === "container") {
        value = value.fields;
        this.recursiveData(value, uiData, result);
      }
    });

    return result;
  }

  getPaymentData() {
    let paymentObj = []
    let paymentObj2 = []
    let norField = ["accountOwnerNameN", "bankNameN"]
    let foreignField = ["accountOwnerNameF", "bankNameF"]
    let norField2 = ["accountOwnerNameN2", "bankNameN2"]
    let foreignField2 = ["accountOwnerNameF2", "bankNameF2"]

    let language= window.location.pathname.split('/')[4];
    let norBank="Norwegian Bank Account";
    let forBank="Foreign Bank Account";
 
    
    let finalPaymentobj =[]



    if ( this.dataStoreService.get(this.step3Name).uiData['bankType']=== norBank) {
        
      let bankType = {
        "questionText": $localize`:@@bankType:Bank Type`,
					"questionCd": "bankType",
				"answerCd":this.getData("bankType", 'rpa', this.step3Name),
        "answer": this.getData("bankType", '', this.step3Name)
      }
      paymentObj.push(bankType)
    


      norField.forEach((i) => {
        paymentObj.push(
          {
            "questionText": this.getData(i, "label", this.step3Name)['label'],
            "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
            "answer": this.getData(i, "", this.step3Name),
            "answerCd": null,

          })
      })


      let accountNum = {
        "questionText": $localize`:@@accountNumberNor:Account Number`,
        "questionCd": "accountNumber",
        "answerCd":null,
        "answer": this.getMaksing(this.getData("accountNumberN", '', this.step3Name))
      }
      paymentObj.push(accountNum)

      let accountNumConfirm = {
        "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
					"questionCd": "confirmAccountNumber",
				"answerCd":null,
        "answer": this.getMaksing(this.getData("confirmAccountNumberN", '', this.step3Name))
      }
      paymentObj.push(accountNumConfirm)
       let accountMailAddF = {
        "questionText": this.getData("accountMailAddF2", 'label', this.step3Name)["label"],
        "questionCd": "accountMailAddNorweign",
        "answerCd": null,
        "answer": this.getData("accountMailAddF2", '', this.step3Name)
      }
      paymentObj.push(accountMailAddF)
      let postalCodeF = {
        "questionText": this.getData("postalCodeF2", 'label', this.step3Name)["label"],
        "questionCd": "postalCodeNorweignBank",
        "answerCd": null,
        "answer": this.getData("postalCodeF2", '', this.step3Name)
      }
      paymentObj.push(postalCodeF)
      let cityF = {
        "questionText": this.getData("cityF2", 'label', this.step3Name)["label"],
        "questionCd": "cityNorweignBank",
        "answerCd": null,
        "answer": this.getData("cityF2", '', this.step3Name)
      }
      paymentObj.push(cityF)
      let countryF = {
        "questionText": this.getData("countryF2", 'label', this.step3Name)["label"],
        "questionCd": "countryNameNorweignBank",
        "answerCd":this.getData("countryF2", 'rpa', this.step3Name),
        "answer": this.getData("countryF2", '', this.step3Name)
      }
      paymentObj.push(countryF)


      finalPaymentobj.push({
        "featureTypeText": $localize`:@@paymentTypeNor:Payment Type`,
        "featureTypeCd": "paymentType1",
        "questionAnswer": paymentObj
      })

    } else if (this.dataStoreService.get(this.step3Name).uiData['bankType']=== forBank) {
      
      let bankType = {
        "questionText": $localize`:@@bankType:Bank Type`,
					"questionCd": "bankType",
				"answerCd":this.getData("bankType", 'rpa', this.step3Name),
        "answer": this.getData("bankType", '', this.step3Name)
      }
      paymentObj.push(bankType)


      foreignField.forEach((i) => {
        paymentObj.push(
          {
            "questionText": this.getData(i, "label", this.step3Name)['label'],
            "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
            "answer": this.getData(i, "", this.step3Name),
            "answerCd": null,
          })
      })

      let accountNum = {
        "questionText": $localize`:@@accountNumberNor:Account Number`,
        "questionCd": "accountNumber",
        "answerCd":null,
        "answer": this.getMaksing(this.getData("accountNumberF", '', this.step3Name))
      }
      paymentObj.push(accountNum)

      let accountNumConfirm = {
        "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
					"questionCd": "confirmAccountNumber",
				"answerCd":null,
        "answer": this.getMaksing(this.getData("confirmAccountNumberF", '', this.step3Name))
      }
      paymentObj.push(accountNumConfirm)


      let ibanApplicableF = {
        "questionText": this.getData("ibanApplicableF", 'label', this.step3Name)["label"],
        "questionCd": "ibanApplicableForeignBank",
        "answerCd": null,
        "answer": this.getData("ibanApplicableF", '', this.step3Name)
      }
      paymentObj.push(ibanApplicableF)

      let bicSwiftF = {
        "questionText": this.getData("bicSwiftF", 'label', this.step3Name)["label"],
        "questionCd": "bicSwiftForeignBank",
        "answerCd": null,
        "answer": this.getData("bicSwiftF", '', this.step3Name)
      }
      paymentObj.push(bicSwiftF)
      let accountMailAddF = {
        "questionText": this.getData("accountMailAddF", 'label', this.step3Name)["label"],
        "questionCd": "accountMailAddForeign",
        "answerCd": null,
        "answer": this.getData("accountMailAddF", '', this.step3Name)
      }
      paymentObj.push(accountMailAddF)
      let postalCodeF = {
        "questionText": this.getData("postalCodeF", 'label', this.step3Name)["label"],
        "questionCd": "postalCodeForeignBank",
        "answerCd": null,
        "answer": this.getData("postalCodeF", '', this.step3Name)
      }
      paymentObj.push(postalCodeF)
      let cityF = {
        "questionText": this.getData("cityF", 'label', this.step3Name)["label"],
        "questionCd": "cityForeignBank",
        "answerCd": null,
        "answer": this.getData("cityF", '', this.step3Name)
      }
      paymentObj.push(cityF)
      let countryF = {
        "questionText": this.getData("countryF", 'label', this.step3Name)["label"],
        "questionCd": "countryNameForeignBank",
        "answerCd":this.getData("countryF", 'rpa', this.step3Name),
         "answer":this.getData("countryF", '', this.step3Name)
      }
      paymentObj.push(countryF)

      finalPaymentobj.push({
        "featureTypeText": $localize`:@@paymentTypeNor:Payment Type`,
        "featureTypeCd": "paymentType1",
        "questionAnswer": paymentObj
      })
    }

    if (this.getData("isPaymentDuplicate", '', this.step3Name)) {
      if (this.getData("bankType2", '', this.step3Name) === norBank) {

        let bankType = {
          "questionText":  $localize`:@@bankType:Bank Type`,
            "questionCd": "bankType",
          "answerCd":null,
          "answer": this.getData("bankType2", '', this.step3Name)
        }
        paymentObj2.push(bankType)


        norField2.forEach((i) => {
          paymentObj2.push(
            {
              "questionText": this.getData(i, "label", this.step3Name)['label'],
              "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
              "answer": this.getData(i, "", this.step3Name),
              "answerCd": null,
            })
        })

        let accountNum = {
          "questionText": $localize`:@@accountNumberNor:Account Number`,
          "questionCd": "accountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("accountNumberN2", '', this.step3Name))
        }
        paymentObj2.push(accountNum)

        let accountNumConfirm = {
          "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
            "questionCd": "confirmAccountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("confirmAccountNumberN2", '', this.step3Name))
        }
        paymentObj2.push(accountNumConfirm)

        // let checkhowToPaySomeOne = {
        //   "questionText": "If claiming for someone else, how should we send the claim payment to them?",
        //   "questionCd": "claimPaymentModeForSomeone",
        //   "answerCd": null,
        //   "answer": this.getData("bankType2", '', this.step3Name)
        // }
        // paymentObj2.push(checkhowToPaySomeOne)



      finalPaymentobj.push({
        "featureTypeText": $localize`:@@pamtype2:Payment Type#2`,
        "featureTypeCd": "paymentType2",
        "questionAnswer": paymentObj2
      })

      } else if (this.getData("bankType2", '', this.step3Name) === forBank) {

        let bankType = {
          "questionText": $localize`:@@bankType:Bank Type`,
            "questionCd": "bankType",
          "answerCd":null,
          "answer": this.getData("bankType2", '', this.step3Name)
        }
        paymentObj2.push(bankType)

        foreignField2.forEach((i) => {

          paymentObj2.push(
            {
              "questionText": this.getData(i, "label", this.step3Name)['label'],
              "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
              "answer": this.getData(i, "", this.step3Name),
              "answerCd": null,
            })
        })

        let accountNum = {
          "questionText": $localize`:@@accountNumberNor:Account Number`,
          "questionCd": "accountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("accountNumberF2", '', this.step3Name))
        }
        paymentObj2.push(accountNum)

        let accountNumConfirm = {
          "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
            "questionCd": "confirmAccountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("confirmAccountNumberF2", '', this.step3Name))
        }
        paymentObj2.push(accountNumConfirm)

        // let checkhowToPaySomeOne = {
        //   "questionText": "If claiming for someone else, how should we send the claim payment to them?",
        //   "questionCd": "claimPaymentModeForSomeone",
        //   "answerCd": null,
        //   "answer": this.getData("bankType2", '', this.step3Name)
        // }
        // paymentObj2.push(checkhowToPaySomeOne)


        let ibanApplicableF2 = {
          "questionText": this.getData("ibanApplicableF2", 'label', this.step3Name)["label"],
          "questionCd": "ibanApplicableForeignBank",
          "answerCd":  this.getData("ibanApplicableF2", 'rpa', this.step3Name),
          "answer": this.getData("ibanApplicableF2", '', this.step3Name)
        }
        paymentObj2.push(ibanApplicableF2)

        let bicSwiftF2 = {
          "questionText": this.getData("bicSwiftF2", 'label', this.step3Name)["label"],
          "questionCd": "bicSwiftForeignBank",
          "answerCd":  this.getData("bicSwiftF2", 'rpa', this.step3Name),
          "answer": this.getData("bicSwiftF2", '', this.step3Name)
        }
        paymentObj2.push(bicSwiftF2)
        let accountMailAddF2 = {
          "questionText": this.getData("accountMailAddF2", 'label', this.step3Name)["label"],
          "questionCd": "accountMailAddForeign",
          "answerCd":  this.getData("accountMailAddF2", 'rpa', this.step3Name),
          "answer": this.getData("accountMailAddF2", '', this.step3Name)
        }
        paymentObj2.push(accountMailAddF2)
        let postalCodeF2 = {
          "questionText": this.getData("postalCodeF2", 'label', this.step3Name)["label"],
          "questionCd": "postalCodeForeignBank",
          "answerCd":  this.getData("postalCodeF2", 'rpa', this.step3Name),
          "answer": this.getData("postalCodeF2", '', this.step3Name)
        }
        paymentObj2.push(postalCodeF2)
        let cityF2 = {
          "questionText": this.getData("cityF2", 'label', this.step3Name)["label"],
          "questionCd": "cityForeignBank",
          "answerCd":  this.getData("cityF2", 'rpa', this.step3Name),
          "answer": this.getData("cityF2", '', this.step3Name)
        }
        paymentObj2.push(cityF2)
        let countryF2 = {
          "questionText": this.getData("countryF2", 'label', this.step3Name)["label"],
          "questionCd": "countryNameForeignBank",
          "answerCd":this.getData("countryF2", 'rpa', this.step3Name),
          "answer": this.getData("countryF2", '', this.step3Name)

        }
        paymentObj2.push(countryF2)

      finalPaymentobj.push({
        "featureTypeText": $localize`:@@pamtype2:Payment Type#2`,
        "featureTypeCd": "paymentType2",
        "questionAnswer": paymentObj2
      })


      }

    }


    return  finalPaymentobj;


    }



  generateSetFnolRequestNordics() {

    let applicationContext = {
      "countryCode": window.location.pathname.split("/")[3].toUpperCase(),
      "language": window.location.pathname.split("/")[4],
      "lineOfBusiness": window.location.pathname.split("/")[5].toUpperCase(),
      "userId": this.config.userEmailID,
      "claimNumber": this.cacheService.getClaimNumber(),
      "subLob": "NA",
      "submissionDate": moment(new Date()).format("DD-MM-YYYY"),
      "submissionTime":  moment(new Date()).format("HH:mm:ss"),
      "countryName": "Norway",
      "product": "Personal Accident"
    }

    return applicationContext;

  }
  getLandingDetails(){
    const landingData ={'lossData':this.dataStoreService.get('fnol-landing').uiStructure[0].value};
    return landingData;
  }
  getRequestPayload() {
   let uiData1= this.dataStoreService.get(this.step1Name).uiData
    let requestObj = {
      "applicationContext": this.generateSetFnolRequestNordics(),
      "lossType": [
        {
          "featureTypeText": $localize`:@@policyTermsNor:Summary of Submission`,
          "featureTypeCd": "lossType",
          "questionAnswer": [
            {
              "questionText": $localize`:@@causeOfClaim:What is the cause of your claim?`,
              "questionCd": "lossType",
               "answerCd": this.nordicsApiService.getLossData()[0].rpaValue,
              "answer":this.getLandingDetails().lossData
            }
          ]
        }
      ],
      
      "personalInformation": [
        {
          "featureTypeText": $localize`:@@SummaryInfoNor:Personal Information`,
          "featureTypeCd": "personalInformation",
          "insuranceInfo": [
            {
              "featureTypeText": $localize`:@@insDet:Insurance Details`,
              "featureTypeCd": "insuranceDetails",
              "questionAnswer": this.getInsuranceInfo()
            }
          ],
          "personalDetailsInfo": this.getPersonalDetailsInfo()
        }

      ],
      "claimDetailsInfo":   {
        "featureTypeText": $localize`:@@claimDetailsNor:Claim Details`,
        "featureTypeCd": "claimDetails",
        "claimDetails": [
          {
            "featureTypeText":this.getFeatureType("text") ,
            "featureTypeCd": this.getFeatureType("code"),
            "questionAnswer": this.getPayloadStep2(),
          }
        ]
          },
      "uploadedDocsInfo": {
        "featureTypeText": $localize`:@@docum:Documents`,
        "featureTypeCd": "documents",
        "questionAnswer": this.getUploadDetails()
      },
      "paymentType":this.getPaymentData(),
      "reviewAndSubmitInfo": {
        "title": $localize`:@@summaryReviewNor:Review and Submit the Claim`,
        "attribute": [
          {
            "name": "I have read and agree to the declarations and authorisation & by submitting this form, I have read and agree with the declarations and authorisation",
            "value": "Yes"
          }
        ]
      },
      "privacyInfo": {
        "title": "Privacy Policy and Terms of Use",
        "attribute": [
          {
            "name": "Privacy Policy:",
            "value": "Yes"
          },
          {
            "name": "Terms of Use:",
            "value": "Yes"
          },
          {
            "name": "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
            "value": "Yes"
          }
        ]
      },
      "correspondenceGenerationData": {
        "correspondenceItemID": "FNOL_AH",
        "userID": "O1111147",
        "requestApplicationID": "FNOL",
        "requestMessageID": this.cacheService.getClaimNumber(),
        "callerDistributionFax": "",
        "callerDistributionEMAIL": "",
        "claimantEmail": uiData1['personalEmail'] ,
        "brokerEmail": "",
        "sharedServiceEmail":  this.config.aigSharedeMailID['no'],
        "isNotificationClaim": "",
        "lossState": "Norway",
        "reportedByFirstName":  uiData1['personalFirstName'],
        "reportedByLastName": uiData1['personalLastName'] ,
        "mobile":this.getData("personalPhone", "", this.step1Name)['phoneCode']['label'] + "-"  + this.getData("personalPhone", "", this.step1Name)['phoneNumber'],
        "businessPhone": "",
        "originKey": "",
        "dateReported": "",
         "lossDate":  moment(this.getLossTypeDate()).format("DD-MM-YYYY"),
        "insuredName":  uiData1['personalFirstName'] + " "  +  uiData1['personalLastName'],
        "clientName": "AIG",
        "systemDate":  moment(new Date()).format("DD-MM-YYYY"),
        "applicationID": "EXTPOR-001",
        "emailLanguage": window.location.pathname.split("/")[4].toUpperCase(),
        "claimFormProduct": "Accident Illness_P Accident"
      }
    }
  
    return requestObj;

  }

  formProduct(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addPersonMain").fields
  let uiData = this.dataStoreService.get(this.step1Name).uiData
  let language= window.location.pathname.split('/')[4] === 'no'?"no":"en";
  if(language === 'en'){
    if ( uiData['chooseYourself'] === "Purchased Myself" || uiData['chooseYourself'] === "Credit Card"  ){
      return "Travel Ind"
    } else if(uiData['chooseYourself'] === "Employer"  || uiData['chooseYourself'] ===  "Union / Association"  ) {
     return "Travel Group"
    } else{
      return "Travel Group"
    }

  } else {
    if ( uiData['chooseYourself'] === "Kjøpt selv" || uiData['chooseYourself'] === "Kredittkort"  ){
      return "Travel Ind"
    } else if(uiData['chooseYourself'] === "Arbeidsgiver"  || uiData['chooseYourself'] ===  "Fagforening / fagforbund"  ) {
     return "Travel Group"
    }
  }

  }



  checkCustom( field,index,step){
    if( this.getData(field + index, "label", step)['type']==="custom" &&
    this.getData(field + index, "label", step)['element']==="CurrencyDropdownComponent"
    ){
      return this.getData(field + index , "", step)['phoneNumber']?
       this.getData(field + index , "", step)['phoneCode'].hasOwnProperty('label')?
       this.getData(field + index , "", step)['phoneCode']['label']+ " "  + this.getData(field + index , "", step)['phoneNumber']:
       this.getData(field + index , "", step)['phoneCode'] + " "  + this.getData(field + index , "", step)['phoneNumber']
        : null
    }
    else {
      return this.getData(field + index, "", step)
    }
  }


  getMaksing(input){

    return input
  }
      
    getInsuredName(){
    return this.dataStoreService.get(this.step1Name).uiData['personalFirstName'] + " " + this.dataStoreService.get(this.step1Name).uiData['personalLastName']
  }
   getLossTypeDate(){
     
      let claimType=this.dataStoreService.get('fnol-landing').uiStructure[0].value
    if(claimType === "Personal Accident" ||  claimType ===  "Personulykke"){
  
      return this.dataStoreService.get(this.step2Name).uiData['dateOfAccident']
    }  else if(claimType  === 'Critical Illness' ||  claimType ===  "Kritisk sykdom"){
      return this.dataStoreService.get(this.step2Name).uiData['diagnosisDate']
    } else if(claimType === 'Accidental Death'||  claimType ===  "Dødsfall som følge av ulykke") {
      return  this.dataStoreService.get(this.step2Name).uiData['dateOfPassing']
    }

  }

   convertDateGMT(datePassed, dateFormat, zeroCheck?): string {
    const dateFormatted = moment(datePassed).format(dateFormat);
    if (zeroCheck && zeroCheck === 'zeroTime') {
      return dateFormatted + 'T000000.000 GMT';
    } else { return dateFormatted + 'T050000.000 GMT'; }
  }
}
