import { Component, ViewChild, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth/services/auth.service";
import { TimerService } from "./shared/services/timer.service";
import { CacheService } from "../app/shared/services/cache.service";
import { apiEndPoints } from "../app/shared/services/utils/apiEndPointConstants";
import { AnalyticsService } from "./features/fnol/services/analytics.service";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { FnolService } from "./features/fnol/services/fnol.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
//test
export class AppComponent implements OnInit {
  @ViewChild("sessionTimeoutModal") sessionTimeoutModal: any;
  public remainingTime = null;
  public timeElapsed = false;
  public noTimerUrls = [
    "/registration/1",
    "/registration/2",
    "/registration/3",
    "/login",
    "/forgot-password",
  ];
  public pauseTimer = false;
  constructor(
    private authService: AuthService,
    private timerService: TimerService,
    private router: Router,
    private fnolService: FnolService,
    private cacheService: CacheService,
    private analyticsService: AnalyticsService,
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth,

  ) { 
    console.log("===========================================");
    console.log("#####9/27/2023 Build 2#####") ;
    console.log("===========================================");
    console.log("===========================================");
    console.log("#####9/28/2023 Build 2#####") ;
    console.log("===========================================");
  }

  ngOnInit() {
    this.oktaAuth
      .isAuthenticated()
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          return this.oktaAuth.getUser();
        }
      })
      .then((user) => {
        if (user) {
          console.log(user);

          this.cacheService.setUserid(user["email"]);
        }
      });
    /*  interval(1000).subscribe((val) => {
      if (this.findUrlInNoTimerUrls(window.location.pathname) == false && this.pauseTimer == false) {
        this.timerService.updateTime();
        if (this.timeElapsed == false) {
          this.remainingTime = this.timerService.getRemainingTime('mm:ss');
        } else {
          this.remainingTime = "00:00";
        }
        //console.log(this.remainingTime);

        if (this.remainingTime == TimerService.CDP_POPUP_TIME) {
        //  this.sessionTimeoutModal.open();
        } else if (this.remainingTime == "00:00") {
          this.timeElapsed = true;
        }
      }
    }); */
    const path = window.location.pathname;
    const region = path.match('jp') || path.match('singapore') || path.match('sg')||path.match('no');
    const queryParams = new URLSearchParams(window.location.search);
    const queryParam = queryParams.get("country");
    let analyticsPath : any = '';

    if (region && region.length) {
      analyticsPath = region[0];
    } else if (queryParam) {
      analyticsPath = queryParam;
    }
    this.analyticsService.addAnalyticsURL(analyticsPath);
    if(window.location.href.indexOf('jp')> -1){
      this.analyticsService.dynaTrace();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const partner = urlParams.get("partner");
    if (partner && partner.toLowerCase() == "gojek") {
      this.router.navigate(["/cdp/error"]);
    }
    const getGegion = path.match('us') || path.match('au') || path.match('nz') ||  path.match('US') || path.match('AU') || path.match('NZ') || path.match('no') ||  path.match('SG');
    if (getGegion) {
      analyticsPath = analyticsPath === "" ? getGegion[0] : analyticsPath
    }
   if (analyticsPath) {
    this.fnolService.setCountry(analyticsPath == "singapore" ? "SG" : analyticsPath);
   }

  }

  findUrlInNoTimerUrls(str) {
    return this.noTimerUrls.some(function (s) {
      return s.toLowerCase().indexOf(str.toLowerCase()) > -1;
    });
  }

  continueTimeout() {
    this.pauseTimer = true;
    if (this.getUserId() != "") {
    }
  }

  logoutUser() {
    this.router.navigate(["/login/logout"]);
  }

  getUserId() {
    // let userId = this.cacheService.get(this.cacheService.USERID, CacheService.COOKIE_STORAGE);
    let userId = apiEndPoints.userid;
    if (userId) {
      return userId;
    } else {
      this.logoutUser();
    }
  }

  navigateToCommonServiceErrorPage() {
    this.router.navigate(["/registration/message"], {
      queryParams: { type: "commonServiceErrorPage" },
    });
  }
}
