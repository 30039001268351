import { isEmpty } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { Utils } from 'axis-ui-generator';
import { Configuration } from 'src/app/shared/configuration';
import * as moment from 'moment';
import { FnolNordicsApiService } from '../../service/common-nordics-api-services';
import { landingPageLabels } from '../../constants/fnol-norway-travel.constant';
import { CacheService } from 'src/app/shared/services/cache.service';
import { AxisInputNumberPipe } from "axis-components";

@Injectable({
  providedIn: 'root'
})
export class DeclarationServiceNoHlfService {

  languagecheck = window.location.pathname.split('/')[4];
  language: string;

  constructor(public axisPipe : AxisInputNumberPipe,private dataStoreService: DataStoreService, private config: Configuration, private cacheService: CacheService, private nordicsApiService: FnolNordicsApiService) { }

  uiStructure;
  uiData;
  mySettings51: any = {
    view_roundoff: false,
    thousands_separator: ".",
    decimal_separator: ",",
    fraction_size: "2",
    reg_exp: "^[\.0-9]*$",
    decimal: "",
    restrict: false
   };
  claimTypeDetails = { "claimDetails": [], "damage": [], "lost": [], "stolen": [] };
  claimDetailsInfo;
  lossTypesData;

  getData(name, type, stepName) {
    this.uiStructure = this.dataStoreService.get(stepName).uiStructure;
    this.uiData = this.dataStoreService.get(stepName).uiData;
    const checkBoxFlag = Utils.getFieldFromStructure(this.uiStructure, name);
    if (type == "label") {
      const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
      if (resultData?.type === "checkbox") {
        return {
          label: resultData?.heading,
          questionCd: resultData?.questionCd,
          heading: resultData?.heading,
          type: resultData?.type
        }
      }
      return resultData;
    } else {
      let language = window.location.pathname.split('/')[4];
      if (language === "en") {
        const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        if (resultData?.type === "datepicker" && this.uiData[name] != 'Invalid date' && this.uiData[name] != null && this.uiData[name] != '') {
          return (this.uiData[name] != null && this.uiData[name] != "") ? moment(new Date(this.uiData[name])).format('DD-MM-YYYY') : this.uiData[name];
        } else if (resultData?.type === "timepicker") {
          return (this.uiData[name] != null && this.uiData[name] != "") && this.uiData[name]?.length > 5 ? moment(this.uiData[name]).format("HH:mm") : this.uiData[name];
        } else if (resultData?.type === "checkbox") {
          return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No') ? "Yes" : "No"
        } else {
          return this.uiData[name]
        }

      }
      else if (language === "no" && Utils.getFieldFromStructure(this.uiStructure, name) && Utils.getFieldFromStructure(this.uiStructure, name).hasOwnProperty('translatedRequired')) {
        const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        console.log(resultData);
        if (this.uiData[name] != null)
          return resultData.options.filter(e => e.value === this.uiData[name])[0]['label']
      }
      else if (language === "no" && checkBoxFlag?.type === 'checkbox') {
        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No') ? "Ja" : "Nei"
      }
      else {
        const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        if (resultData?.type === "datepicker" && this.uiData[name] != 'Invalid date' && this.uiData[name] != null && this.uiData[name] != '') {
          return (this.uiData[name] != null || this.uiData[name] != "") ? moment(new Date(this.uiData[name])).format('DD-MM-YYYY') : this.uiData[name];
        } else if (resultData?.type === "timepicker") {
          return (this.uiData[name] != null || this.uiData[name] != "") && this.uiData[name]?.length > 5 ? moment(this.uiData[name]).format("HH:mm") : this.uiData[name];
        }
        else {
          if(resultData  && resultData.type === "addressLookup" && typeof this.uiData[name] === 'object' ){
            return this.uiData[name]['viewModel'] ||  this.uiData[name]['name']
          } else{
            return this.uiData[name]
          }
        }




      }

    }
  }
  step1Name = 'fnol-claimant-info';
  step2Name = 'fnol-claimtype-details';
  step3Name = 'fnol-payment-details';

  getInsuranceInfo() {

    let uiData = this.dataStoreService.get(this.step1Name).uiData
    let insuranceDetailssInfo = [];
      insuranceDetailssInfo.push(
        {
          "questionText": this.getData("membershipNumber", "label", this.step1Name)['label'],
          "questionCd": this.getData("membershipNumber", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("membershipNumber", "", this.step1Name)
        })
      
        insuranceDetailssInfo.push(
          {
            "questionText": this.getData("isPurchasedPlusInsuranceViaHLF", "label", this.step1Name)['label'],
            "questionCd": this.getData("isPurchasedPlusInsuranceViaHLF", "label", this.step1Name)['questionCd'],
            "answerCd": this.uiData['isPurchasedPlusInsuranceViaHLF'],
            "answer": this.getData("isPurchasedPlusInsuranceViaHLF", "", this.step1Name)
          })
        let answer = this.getData("isPurchasedPlusInsuranceViaHLF", "", this.step1Name);
        if (answer === "Yes" || answer === "Ja") {
          insuranceDetailssInfo.push(
            {
              "questionText": this.getData("plusInsuranceNumber", "label", this.step1Name)['label'],
              "questionCd": this.getData("plusInsuranceNumber", "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("plusInsuranceNumber", "", this.step1Name)
            })
        }

    if (uiData['anotherInsurancePresent'] === 'Yes') {
        insuranceDetailssInfo.push(
          { 
            "questionText": this.getData("anotherInsurancePresent", "label", this.step1Name)['label'],
            "questionCd": this.getData("anotherInsurancePresent", "label", this.step1Name)['questionCd'],
            "answerCd": 'Yes',
            "answer": this.getData("anotherInsurancePresent", "", this.step1Name),
            "additionalInfo": this.getAdditionalInsurance()
          });
        }
      else{
        insuranceDetailssInfo.push(
          { 
            "questionText": this.getData("anotherInsurancePresent", "label", this.step1Name)['label'],
            "questionCd": this.getData("anotherInsurancePresent", "label", this.step1Name)['questionCd'],
            "answerCd": 'No',
            "answer": this.getData("anotherInsurancePresent", "", this.step1Name)
          });
      }
    return insuranceDetailssInfo;
  }
  getAdditionalInsurance() {
    let additionalInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "anotherInsurancePresent-Yes").fields
    let addMainArr = []
    additionalInsurance.forEach((data, i) => {
      let insArr = []
      const index = i > 0 ? i + 1 : "";
      const labelIndex = index != "" ? (index - 1) : index;
      const nonBagNoEMp = ["companyName", "policyNumber", "policyType", "isClaimReported"];
      nonBagNoEMp.forEach((item, i) => {
        if (this.getData(item + index, "label", this.step1Name)["type"] == "checkbox") {
          insArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.uiData[item + index] ?? 'No' ,
            "answer": this.getData(item + index, "", this.step1Name)
          });

        } else {
          insArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      addMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + (labelIndex + 1),
        "featureTypeCd": "additionalInsurance" + (labelIndex + 1),
        "questionAnswer": insArr
      })
    })
    return addMainArr;
  }
  getLostClaimDetails() {
    let lostClaimDetails = ["lostMomentKnown", "incidentHappened-lost", "lostIssueDate", "numberofAidsLost", "orderedReplacementLost"];
    let lostMomentKnownYes = ["lostIncidentDate-Yes", "lostIncidentTime-Yes", "lostCountry-yes", "lostAddress-Yes", "checkLost"];
    let lostMomentKnownNo = ["lostDate", "lostTime", "lostCountry-no", "lostLocationDetails", "lostRememberDate", "lostRememberTime"];
    let uiData = this.dataStoreService.get(this.step2Name).uiData;
    let lostClaimDetailInfo = [];
    lostClaimDetails.forEach(det => {
      let fieldType = this.getData(det, "label", this.step2Name)['type'];

      if(fieldType === 'radio' && det !== "numberofAidsLost"){
       lostClaimDetailInfo.push(
        {
          "questionText": this.getData(det, "label", this.step2Name)['label'],
          "questionCd": this.getData(det, "label", this.step2Name)['questionCd'],
          "answerCd": this.dataStoreService.get(this.step2Name).uiData[det],
          "answer": this.getData(det, "", this.step2Name)
        }); 
        if (det === "lostMomentKnown") {
          let lostMomentKnown = this.getData(det, "", this.step2Name);          
          if (lostMomentKnown === "Yes" || lostMomentKnown === "Ja") {
            lostMomentKnownYes.forEach(field => {
              if (field === "checkLost"){
                lostClaimDetailInfo.push(
                  {
                    "questionText": this.getData(field, "label", this.step2Name)['label'],
                    "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                    "answerCd": this.dataStoreService.get(this.step2Name).uiData[field]?? 'No',
                    "answer": this.getData(field, "", this.step2Name)
                  }); 
              }
              else if (field.indexOf('Country') > -1) {
                lostClaimDetailInfo.push(
                  {
                    "questionText": this.getData(field, "label", this.step2Name)['label'],
                    "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                    "answerCd": this.getData(field, "", this.step2Name).code,
                    "answer": this.getData(field, "", this.step2Name).name
                  })
              }
              else {
                lostClaimDetailInfo.push(
                  {
                    "questionText": this.getData(field, "label", this.step2Name)['label'],
                    "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                    "answerCd": null,
                    "answer": this.getData(field, "", this.step2Name)
                  })
              }
            });
          }
          else {
            lostMomentKnownNo.forEach(field => {
              if (field.indexOf('Country') > -1) {
                lostClaimDetailInfo.push(
                  {
                    "questionText": this.getData(field, "label", this.step2Name)['label'],
                    "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                    "answerCd": this.getData(field, "", this.step2Name).code,
                    "answer": this.getData(field, "", this.step2Name).name
                  })
              }
              else if (field === "lostTime") {
                let lostTime = this.getData(field, "", this.step2Name); {
                  if (lostTime !== "" && lostTime !== null) {
                    lostClaimDetailInfo.push(
                      {
                        "questionText": this.getData(field, "label", this.step2Name)['label'],
                        "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                        "answerCd": null,
                        "answer": this.getData(field, "", this.step2Name)
                      })
                  }
                }
              }
              else {
                lostClaimDetailInfo.push(
                  {
                    "questionText": this.getData(field, "label", this.step2Name)['label'],
                    "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                    "answerCd": null,
                    "answer": this.getData(field, "", this.step2Name)
                  });
              }
            });
          }
        }
        else if (det === "incidentHappened-lost") {
          let incidentHappenedlost = this.getData(det, "", this.step2Name);
          {
            if (incidentHappenedlost === "Yes" || incidentHappenedlost === "Ja") {
              lostClaimDetailInfo.push(
                {
                  "questionText": this.getData("descOfIncidentLost", "label", this.step2Name)['label'],
                  "questionCd": this.getData("descOfIncidentLost", "label", this.step2Name)['questionCd'],
                  "answerCd": null,
                  "answer": this.getData("descOfIncidentLost", "", this.step2Name)
                })
            }
          }
        }
        else if (det === "orderedReplacementLost") {
          let orderedReplacement = this.getData(det, "", this.step2Name);
          if (orderedReplacement === "Yes" || orderedReplacement === "Ja") {
            lostClaimDetailInfo.push(
              {
                "questionText": this.getData("priceLost", "label", this.step2Name)['label'],
                "questionCd": this.getData("priceLost", "label", this.step2Name)['questionCd'],
                "answerCd": null,
                "answer": "NOK " + this.axisPipe.transform(this.getData("priceLost", "", this.step2Name).toString(),this.mySettings51)
              });
          }
        }
      }
      else{
      lostClaimDetailInfo.push(
        {
          "questionText": this.getData(det, "label", this.step2Name)['label'],
          "questionCd": this.getData(det, "label", this.step2Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData(det, "", this.step2Name)
        });
    }
    });

    return lostClaimDetailInfo;
  }

  getDamageClaimDetails() {
    let damagedClaimDetails = ["hearingAidDamage", "incidentHappenedDamage", "damageIssueDate", "numberofAidsDamaged", "damageReported", "orderedReplacementDamaged"];
    let damageKnownYes = ["dateOfIncidentDamage", "timeOfIncidentDamage", "damageCountry-yes", "damageLocationAddress", "checkDamaged"];
    let damageKnownNo = ["damageDate", "damageTime", "damageCountry-no", "locationDetails", "detailDescription"];
    let uiData = this.dataStoreService.get(this.step2Name).uiData;
    let damageClaimDetailInfo = [];
    damagedClaimDetails.forEach(det => {
      let fieldType = this.getData(det, "label", this.step2Name)['type'];
      if(fieldType === 'radio' && det !== "numberofAidsDamaged"){
      damageClaimDetailInfo.push(
        {
          "questionText": this.getData(det, "label", this.step2Name)['label'],
          "questionCd": this.getData(det, "label", this.step2Name)['questionCd'],
          "answerCd": this.dataStoreService.get(this.step2Name).uiData[det],
          "answer": this.getData(det, "", this.step2Name)
        });

      if (det === "hearingAidDamage") {
        let damageMomentKnown = this.getData(det, "", this.step2Name);
        if (damageMomentKnown === "Yes" || damageMomentKnown === "Ja") {
          damageKnownYes.forEach(item => {
            if(item === 'checkDamaged'){
              damageClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": this.dataStoreService.get(this.step2Name).uiData[item]?? 'No',
                  "answer": this.getData(item, "", this.step2Name)
                });
            }
            else if (item.indexOf('Country') > -1) {
              damageClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": this.getData(item, "", this.step2Name).code,
                  "answer": this.getData(item, "", this.step2Name).name
                })
            }
            else {
              damageClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": null,
                  "answer": this.getData(item, "", this.step2Name)
                });
            }
          }
          );
        }
        else {
          damageKnownNo.forEach(item => {
            if (item.indexOf('Country') > -1) {
              damageClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": this.getData(item, "", this.step2Name).code,
                  "answer": this.getData(item, "", this.step2Name).name
                })
            }
            else if (item === "damageTime") {
              let damageTime = this.getData(item, "", this.step2Name); {
                if (damageTime !== "" && damageTime!== null) {
                  damageClaimDetailInfo.push(
                    {
                      "questionText": this.getData(item, "label", this.step2Name)['label'],
                      "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                      "answerCd": null,
                      "answer": this.getData(item, "", this.step2Name)
                    })
                }
              }
            }
            else {
              damageClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": null,
                  "answer": this.getData(item, "", this.step2Name)
                });
            }
          })
        }
      }
      else if (det === "incidentHappenedDamage") {
        let incidentHappenedDamage = this.getData(det, "", this.step2Name);
        if (incidentHappenedDamage === "Yes" || incidentHappenedDamage === "Ja") {
          damageClaimDetailInfo.push(
            {
              "questionText": this.getData("descOfIncidentDamaged", "label", this.step2Name)['label'],
              "questionCd": this.getData("descOfIncidentDamaged", "label", this.step2Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("descOfIncidentDamaged", "", this.step2Name)
            });
        }
      }
      else if (det === "orderedReplacementDamaged") {
        let orderedReplacementDamaged = this.getData(det, "", this.step2Name);
        if (orderedReplacementDamaged === "Yes" || orderedReplacementDamaged === "Ja") {
          damageClaimDetailInfo.push(
            {
              "questionText": this.getData("priceDamaged", "label", this.step2Name)['label'],
              "questionCd": this.getData("priceDamaged", "label", this.step2Name)['questionCd'],
              "answerCd": null,
              "answer": "NOK " + this.axisPipe.transform(this.getData("priceDamaged", "", this.step2Name).toString(),this.mySettings51)
            });
        }
      }
    }
    else{
      damageClaimDetailInfo.push(
        {
          "questionText": this.getData(det, "label", this.step2Name)['label'],
          "questionCd": this.getData(det, "label", this.step2Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData(det, "", this.step2Name)
        });
    }
  });

    return damageClaimDetailInfo;
  }

  getStolenClaimDetails() {
    let stolenClaimDetails = ["hearingAidStolen", "incidentHappenedStolen", "stolenIssueDate", "numberofAidsStolen", "reportedToPolice", "orderedReplacementStolen"];
    let stolenKnownYes = ["dateOfIncidentStolen", "timeOfIncidentStolen", "stolenCountry-yes", "locationAddressStolen"];
    let stolenKnownNo = ["stolenDate", "stolenTime", "stolenCountry-no", "locationDetailsStolen", "stolenRememberDate", "stolenRememberTime"];
    let uiData = this.dataStoreService.get(this.step2Name).uiData;
    let stolenClaimDetailInfo = [];
    stolenClaimDetails.forEach(det => {
      let fieldType = this.getData(det, "label", this.step2Name)['type'];
      if(fieldType === 'radio' && det !== "numberofAidsStolen"){
      stolenClaimDetailInfo.push(
        {
          "questionText": this.getData(det, "label", this.step2Name)['label'],
          "questionCd": this.getData(det, "label", this.step2Name)['questionCd'],
          "answerCd": this.dataStoreService.get(this.step2Name).uiData[det],
          "answer": this.getData(det, "", this.step2Name)
        });

      if (det === "hearingAidStolen") {
        let stolenMomentKnown = this.getData(det, "", this.step2Name);
        if (stolenMomentKnown === "Yes" || stolenMomentKnown === "Ja") {
          stolenKnownYes.forEach(item => {
            if (item.indexOf('Country') > -1) {
              stolenClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": this.getData(item, "", this.step2Name).code,
                  "answer": this.getData(item, "", this.step2Name).name
                })
            }
            else {
              stolenClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": null,
                  "answer": this.getData(item, "", this.step2Name)
                });
            }
          }
          );
        }
        else {
          stolenKnownNo.forEach(item => {
            if (item.indexOf('Country') > -1) {
              stolenClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": this.getData(item, "", this.step2Name).code,
                  "answer": this.getData(item, "", this.step2Name).name
                })
            }
            else if (item === "stolenTime") {
              let stolenTime = this.getData(item, "", this.step2Name); {
                if (stolenTime !== "" && stolenTime !== null) {
                  stolenClaimDetailInfo.push(
                    {
                      "questionText": this.getData(item, "label", this.step2Name)['label'],
                      "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                      "answerCd": null,
                      "answer": this.getData(item, "", this.step2Name)
                    })
                }
              }
            }
            else {
              stolenClaimDetailInfo.push(
                {
                  "questionText": this.getData(item, "label", this.step2Name)['label'],
                  "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
                  "answerCd": null,
                  "answer": this.getData(item, "", this.step2Name)
                });
            }
          })
        }
      }
      else if (det === "incidentHappenedStolen") {
        let incidentHappenedStolen = this.getData(det, "", this.step2Name);
        if (incidentHappenedStolen === "Yes" || incidentHappenedStolen === "Ja") {
          stolenClaimDetailInfo.push(
            {
              "questionText": this.getData("descOfIncident", "label", this.step2Name)['label'],
              "questionCd": this.getData("descOfIncident", "label", this.step2Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("descOfIncident", "", this.step2Name)
            });
        }
      }
      else if (det === "reportedToPolice") {
        let reportedToPolice = this.getData(det, "", this.step2Name);
        if (reportedToPolice === "Yes" || reportedToPolice === "Ja") {
          let crimeReferenceNumber = this.getData(det, "", this.step2Name);
          if (crimeReferenceNumber.length !== 0) {
            stolenClaimDetailInfo.push(
              {
                "questionText": this.getData("crimeReferenceNumber", "label", this.step2Name)['label'],
                "questionCd": this.getData("crimeReferenceNumber", "label", this.step2Name)['questionCd'],
                "answerCd": null,
                "answer": this.getData("crimeReferenceNumber", "", this.step2Name)
              });
          }
        }
      }
      else if (det === "orderedReplacementStolen") {
        let orderedReplacementStolen = this.getData(det, "", this.step2Name);
        if (orderedReplacementStolen === "Yes" || orderedReplacementStolen === "Ja") {
          stolenClaimDetailInfo.push(
            {
              "questionText": this.getData("priceStolen", "label", this.step2Name)['label'],
              "questionCd": this.getData("priceStolen", "label", this.step2Name)['questionCd'],
              "answerCd": null,
              "answer": "NOK " +this.axisPipe.transform(this.getData("priceStolen", "", this.step2Name).toString(),this.mySettings51)
            });
        }
      }
    }
    else{
      stolenClaimDetailInfo.push(
        {
          "questionText": this.getData(det, "label", this.step2Name)['label'],
          "questionCd": this.getData(det, "label", this.step2Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData(det, "", this.step2Name)
        });
    }
    });

    return stolenClaimDetailInfo;
  }
  getMyself() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let myselfDetails = ["chooseYourselfAccident"];
    let myselfDetailsInfo = [];
    myselfDetails.forEach(res => {
      myselfDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answer": this.getData(res, "", this.step1Name),
          "answerCd": null,
        })
    })

    if (uiData['purchasedInsurance'] === "Yes") {
      let dep = []
      myselfDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you have any other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("purchasedInsurance", "", this.step1Name)
      })
    } else {
      myselfDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you have any other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("purchasedInsurance", "", this.step1Name),
      })
    }



    return myselfDetailsInfo

  }

  getUploadDetails() {
    let obj = []
    if (this.dataStoreService.get("fnol-upload-docs").uiData.customFileUpload) {
      let fileList = this.dataStoreService.get("fnol-upload-docs").uiData.customFileUpload.uploads;

      fileList.forEach((element, i) => {
        if(this.language==='en'){
          obj.push(
            {
              "questionText": element.claimBenefitType,
              "questionCd": "document" + (i + 1),
              "answerCd": null,
              "answer": element.name
            }
      
          )
        }
        else{
        obj.push(
          {
            "questionText": element.displayDocType,
            "questionCd": "document" + (i + 1),
            "answerCd": null,
            "answer": element.name
          }
    
        )
        }
      });

      return obj;
    } else {
      return obj;
    }
  }

  recursiveData(dataArr, uiData, result = []) {
    // var r = result;
    dataArr.forEach((field, i) => {
      let value = field;
      if (value.type === "text" || value.type === "radio" || value.type === "dropdown" || value.type === "datepicker" || value.type === "custom" ||
        value.type === "checkbox" || value.type === "textarea" || value.type === "timepicker") {
        if (value.type === "datepicker") {
          this.uiData[value.name] = (this.uiData[value.name] != null || this.uiData[value.name] != "") ? moment(this.uiData[value.name]).format('DD-MM-YYYY') : this.uiData[value.name];
        }
        result.push(value);


        // r.push(value);
      } else if (value.type === "container") {
        value = value.fields;
        this.recursiveData(value, uiData, result);
      }
    });

    return result;
  }

  getPaymentData() {
    let paymentObj = []
    let paymentFields = ["accountOwnerName", "bankName"];

    paymentFields.forEach((i) => {
      paymentObj.push(
        {
          "questionText": this.getData(i, "label", this.step3Name)['label'],
          "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
          "answer": this.getData(i, "", this.step3Name),
          "answerCd": null,
        })
    })

    let accountNum = {
      "questionText": $localize`:@@accountNumberNor:Account Number`,
      "questionCd": "accountNumber",
      "answerCd": null,
      "answer": this.getMasking(this.getData("accountNumber", '', this.step3Name))
    }
    paymentObj.push(accountNum)

    let accountNumConfirm = {
      "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
      "questionCd": "confirmAccountNumber",
      "answerCd": null,
      "answer": this.getMasking(this.getData("confirmAccountNumber", '', this.step3Name))
    }
    paymentObj.push(accountNumConfirm)
    let accountMailAddF = {
      "questionText": this.getData("accountMailAddF", 'label', this.step3Name)["label"],
      "questionCd": "accountMailAddForeign",
      "answerCd": null,
      "answer": this.getData("accountMailAddF", '', this.step3Name)
    }
    paymentObj.push(accountMailAddF)
    let postalCodeF = {
      "questionText": this.getData("postalCodeF", 'label', this.step3Name)["label"],
      "questionCd": "postalCodeForeignBank",
      "answerCd": null,
      "answer": this.getData("postalCodeF", '', this.step3Name)
    }
    paymentObj.push(postalCodeF)
    let cityF = {
      "questionText": this.getData("cityF", 'label', this.step3Name)["label"],
      "questionCd": "cityForeignBank",
      "answerCd": null,
      "answer": this.getData("cityF", '', this.step3Name)
    }
    paymentObj.push(cityF)
    let countryF = {
      "questionText": this.getData("countryF", 'label', this.step3Name)["label"],
      "questionCd": "countryNameForeignBank",
      "answerCd":this.getData("countryF", '', this.step3Name).rpaValue,
      "answer": this.getData("countryF", '', this.step3Name)?.name ? this.getData("countryF", '', this.step3Name)?.name : this.getData("countryF", '', this.step3Name)
    }
    paymentObj.push(countryF)

    return paymentObj;
  }

  getMasking(input) {

    return input
  }

  generateSetFnolRequestNordics() {

    let applicationContext = {
      "countryCode": window.location.pathname.split("/")[3].toUpperCase(),
      "language": window.location.pathname.split("/")[4],
      "lineOfBusiness": window.location.pathname.split("/")[5].toUpperCase(),
      "userId": this.config.userEmailID,
      "claimNumber": this.cacheService.getClaimNumber(),
      "subLob": "NA",
      "submissionDate": moment(new Date()).format("DD-MM-YYYY"),
      "submissionTime": moment(new Date()).format("HH:mm:ss"),
      "countryName": "Norway",
      "product": "Personal Lines"
    }

    return applicationContext;

  }
  getLandingDetails() {
    let lossData =  this.dataStoreService.get('fnol-landing').uiStructure[0]
    const landingData = { 'lossData': lossData.value ,'rpaValue':  lossData.rpaValue};
    return landingData;
  }
  getRequestPayload() {
    let uiData1 = this.dataStoreService.get(this.step1Name).uiData
    let requestObj = {
      "applicationContext": this.generateSetFnolRequestNordics(),
      "lossType": [
        {
          "featureTypeText": $localize`:@@policyTermsNor:Loss Type`,
          "featureTypeCd": "lossType",
          "questionAnswer": [
            {
              "questionText": $localize`:@@whatHappenToClaimNo:What happened to the hearing aids?`,
              "questionCd": "lossType",
              "answerCd": this.getLandingDetails().rpaValue,
              "answer": this.getLandingDetails().lossData
            }
          ]
        }
      ],

      "personalInformation": [
        {
          "featureTypeText": $localize`:@@SummaryInfoNor:Personal Information`,
          "featureTypeCd": "personalInformation",
          "insuranceInfo": [
            {
              "featureTypeText": $localize`:@@insDet:Insurance Details`,
              "featureTypeCd": "insuranceDetails",
              "questionAnswer": this.getInsuranceInfo()
            }
          ],
          "personalDetailsInfo": this.getPersonalDetailsInfo()
        }
      ],
      "claimDetailsInfo": {
        "featureTypeText":  $localize`:@@claimDetails:Claim Details`,
        "featureTypeCd": "claimDetails",
        "claimDetails": [
          {
            "featureTypeText": this.getClaimFeatureText(),
            "featureTypeCd": this.getClaimFeatureCode(),
            "questionAnswer": this.getPayloadStep2()
          }
        ]
      },
      "uploadedDocsInfo": {
        "featureTypeText": $localize`:@@docum:Documents`,
        "featureTypeCd": "documents",
        "questionAnswer": this.getUploadDetails()
      },
      "paymentType": [
        {
          "featureTypeText": $localize`:@@paymentTypeNor:Payment Type`,
          "featureTypeCd": "paymentType1",
          "questionAnswer": this.getPaymentData()
        }],
      "reviewAndSubmitInfo": {
        "title": $localize`:@@summaryReviewNor:Review and Submit the Claim`,
        "attribute": [
          {
            "name": "I have read and agree to the declarations and authorisation & by submitting this form, I have read and agree with the declarations and authorisation",
            "value": "Yes"
          }
        ]
      },
      "privacyInfo": {
        "title": "Privacy Policy and Terms of Use",
        "attribute": [
          {
            "name": "Privacy Policy:",
            "value": "Yes"
          },
          {
            "name": "Terms of Use:",
            "value": "Yes"
          },
          {
            "name": "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
            "value": "Yes"
          }
        ]
      },
      "correspondenceGenerationData": {
        "correspondenceItemID": "FNOL_AH",
        "userID": "O1111147",
        "requestApplicationID": "FNOL",
        "requestMessageID": this.cacheService.getClaimNumber(),
        "callerDistributionFax": "",
        "callerDistributionEMAIL": "",
        "claimantEmail": uiData1['emailAddress'],
        "brokerEmail": "",
        "sharedServiceEmail": this.config.aigSharedeMailID['no'],
        "isNotificationClaim": "",
        "lossState": "Norway",
        "reportedByFirstName": uiData1['personalFirstName'],
        "reportedByLastName": uiData1['personalLastName'],
        "mobile": this.getData("personalPhone", "", this.step1Name)['phoneCode']['label'] + "-" + this.getData("personalPhone", "", this.step1Name)['phoneNumber'],
        "businessPhone": "",
        "originKey": "",
        "dateReported": "",
        "lossDate": moment(this.getLossTypeDateNew()).format("DD-MM-YYYY"),
        "insuredName": uiData1['personalFirstName'] + " " + uiData1['personalLastName'],
        "clientName": "AIG",
        "systemDate": moment(new Date()).format("DD-MM-YYYY"),
        "applicationID": "EXTPOR-001",
        "emailLanguage": window.location.pathname.split("/")[4].toUpperCase(),
        "claimFormProduct": "HLF_HLF"
      }
    }

    return requestObj;

  }

  getPayloadStep2() {
    let claimType = this.dataStoreService.get('fnol-landing').uiStructure[0].value
    if (claimType.toLowerCase() === "lost" || claimType === 'Tapt / mistet') {
      return this.getLostClaimDetails();
    }
    else if (claimType.toLowerCase() === "broken / damaged" || claimType === 'Ødelagt / skadet') {
      return this.getDamageClaimDetails();
    }
    else if (claimType.toLowerCase() === "stolen" || claimType === 'Tyveri') {
      return this.getStolenClaimDetails();
    }
  }

  getClaimFeatureText(){
    let claimType = this.dataStoreService.get('fnol-landing').uiStructure[0].value;
    let language = window.location.pathname.split('/')[4];
      return language == 'en' ? claimType + ' ' + 'Details' : claimType + ' ' + 'detaljer';
  }

  getClaimFeatureCode() {
    let claimType = this.dataStoreService.get('fnol-landing').uiStructure[0].value
    if (claimType.toLowerCase() === "lost" || claimType === 'Tapt / mistet') {
      return "lostAid";
    }
    else if (claimType.toLowerCase() === "broken / damaged" || claimType === 'Ødelagt / skadet') {
      return "damagedAid";
    }
    else if (claimType.toLowerCase() === "stolen" || claimType === 'Tyveri') {
      return "stolenAid";
    }
  }
  getLossTypeDate() {
    return this.nordicsApiService.getLossTypePrefillDate();

  }

  getLossTypeDateNew(){
    let claimType=this.dataStoreService.get('fnol-landing').uiStructure[0].value;
  if(claimType === "Lost" ||  claimType ===  "Tapt / mistet"){
    return this.dataStoreService.get(this.step2Name).uiData['lostMomentKnown'] === "Yes" ? this.dataStoreService.get(this.step2Name).uiData['lostIncidentDate-Yes'] : this.dataStoreService.get(this.step2Name).uiData['lostDate'];
  }
  if(claimType === "Broken / Damaged" ||  claimType ===  "Ødelagt / skadet"){
    return this.dataStoreService.get(this.step2Name).uiData['hearingAidDamage'] === "Yes" ? this.dataStoreService.get(this.step2Name).uiData['dateOfIncidentDamage'] : this.dataStoreService.get(this.step2Name).uiData['damageDate'];
  }
  if(claimType === "Stolen" ||  claimType ===  "Tyveri"){
    return this.dataStoreService.get(this.step2Name).uiData['hearingAidStolen'] === "Yes" ? this.dataStoreService.get(this.step2Name).uiData['dateOfIncidentStolen'] : this.dataStoreService.get(this.step2Name).uiData['stolenDate'];
  }
}

  getPersonalDetailsInfo() {
    let personalDetailsArray = []

    let personalObj = {
      "featureTypeText": $localize`:@@personalDetailsNor:Personal Details`,
      "featureTypeCd": "claimant",
      "questionAnswer": this.getPersonalObj()
    }
    personalDetailsArray.push(personalObj)
    return personalDetailsArray.concat(this.getPersonalContainerVal());
  }

  getPersonalObj() {
    let personalInfo = ["personalFirstName", "personalLastName", "emailAddress"];
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let personalDetailsInfo = [];
    personalInfo.forEach(res => {
      personalDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd": null,

          "answer": this.getData(res, "", this.step1Name)
        })
    })

    personalDetailsInfo.push(
      {
        "questionText": $localize`:@@phoneNumberNor:Phone Number`,
        "questionCd": "phoneNumber",
        "answerCd": null,
        "answer": this.getData("personalPhone", "", this.step1Name)['phoneCode'].hasOwnProperty('label') ? this.getData("personalPhone", "", this.step1Name)['phoneCode']['label'] + "-" + this.getData("personalPhone", "", this.step1Name)['phoneNumber'] : this.getData("personalPhone", "", this.step1Name)['phoneCode'] + "-" + this.getData("personalPhone", "", this.step1Name)['phoneNumber']
      })
    personalDetailsInfo.push(
      {
        "questionText": $localize`:@@iApprovemail:Do you approve to receive communication by email?`,
         "questionCd":  "emailCommunication",
          "answerCd": null,
          "answer":  this.dataStoreService.get(this.step1Name).uiData['personalCheck'] === "Yes"? $localize`:@@yesNor:Yes`: $localize`:@@noNor:No`
      })

    return personalDetailsInfo;
  }

  getPersonalContainerVal() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;

    let SelectPersonArr = []
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addPersonMain").fields
    travelInsurance.forEach((data, i) => {
      let personalDetailsInfo = [];

      const index = i > 0 ? i + 1 : "";
      const labelIndex = index != "" ? (index - 1) : index;
      personalDetailsInfo.push({
        "questionText": this.getData("selectPerson" + index, "label", this.step1Name)['label'],
        "questionCd": this.getData("selectPerson" + index, "label", this.step1Name)['questionCd'],
        "answerCd": this.getData("selectPerson" + index, "", this.step1Name).id,
        "answer": this.getData("selectPerson" + index, "", this.step1Name).name
      });

      let type = []
      if (uiData["selectPerson" + index].id === "1") {
        type = ["nationalIdentity", "yourselfMailingAddress", "yourselfPostalCode", "yourselfCity", "yourselfCountry"]
      } else if (uiData["selectPerson" + index].id === "2") {
        type = ["someoneElseRelationship", "someoneElseFirstName", "someoneElseLastName", "someoneElseNationalIdentity", "someoneElseCheck", "someoneElseMailingAddress", "someoneElsePostalCode", "someoneElseCity", "someoneElseCountry"];
        if (uiData["someoneElseRelationship" + index].id === "4") {
          type.splice(1, 0, "someoneElseOthers");
        }

      }

      type.forEach((item, i) => {
        if (this.getData(item + index, "label", this.step1Name)['type'] === "dropdown") {
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "", this.step1Name).id,
            "answer": this.getData(item + index, "", this.step1Name).name ? this.getData(item + index, "", this.step1Name).name : this.getData(item + index, "", this.step1Name)
          });
        }
        else if (item === "yourselfMailingAddress" || item === "someoneElseMailingAddress" || item === "employerMailingAddress") {
          // console.log()
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index + "val", "", this.step1Name) ? this.getData(item + index + "val", "", this.step1Name) : this.getData(item + index, "", this.step1Name).formatted_address ? this.getData(item + index, "", this.step1Name).formatted_address : this.getData(item + index, "", this.step1Name)
          });


        }
        else if(item==="someoneElseCheck"){
          let someoneElseCheck=this.getData("someoneElseCheck" + index, "", this.step1Name);
          if(someoneElseCheck==="No" || someoneElseCheck==="Nei")
          {
            personalDetailsInfo.push({
              "questionText": this.getData("someoneElseEmail" + index, "label", this.step1Name)['label'],
              "questionCd": this.getData("someoneElseEmail" + index, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("someoneElseEmail" + index, "", this.step1Name)
            });

            let someoneElsePhone=this.getData("someoneElsePhone" + index, "", this.step1Name);
            if(someoneElsePhone['phoneNumber']!=="")
            {
            personalDetailsInfo.push(
            {
              "questionText": $localize`:@@phoneNumberNor:Phone Number`,
              "questionCd": "phoneNumber",
              "answerCd": null,
              "answer": this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneNumber'] : this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneCode'] + "-"  + this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneNumber']
            });
            }
            personalDetailsInfo.push({
              "questionText": this.getData("someoneElseCheck" + index, "label", this.step1Name)['label'],
              "questionCd": this.getData("someoneElseCheck" + index, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("someoneElseCheck" + index, "", this.step1Name)
            });
          }
          if(someoneElseCheck==="Yes" || someoneElseCheck==="Ja")
          {
            personalDetailsInfo.push({
              "questionText": this.getData("someoneElseCheck" + index, "label", this.step1Name)['label'],
              "questionCd": this.getData("someoneElseCheck" + index, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("someoneElseCheck" + index, "", this.step1Name)
            });
          }

        } else {
          personalDetailsInfo.push({
            "questionText": ((item + index).indexOf('nationalIdentity' + index) > -1 || ((item + index).indexOf('someoneElseNationalIdentity' + index) > -1) || ((item + index).indexOf('employerNationalIdentity' + index) > -1)) ? $localize`:@@nationIdent:National Identity Number` : this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": ((item + index).indexOf('nationalIdentity' + index) > -1 || ((item + index).indexOf('someoneElseNationalIdentity' + index) > -1) || ((item + index).indexOf('employerNationalIdentity' + index) > -1)) ? item : this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": ((item + index).indexOf('nationalIdentity' + index) > -1 || ((item + index).indexOf('someoneElseNationalIdentity' + index) > -1) || ((item + index).indexOf('employerNationalIdentity' + index) > -1)) ? this.getMasking(this.getData(item + index, "", this.step1Name)) : this.getData(item + index, "", this.step1Name)
          });
        }

      });

      let headIndex = index != "" ? index : 1

      SelectPersonArr.push({
        "featureTypeText": "Person #" + headIndex,
        "featureTypeCd": "person" + headIndex,
        "questionAnswer": personalDetailsInfo
      }

      )

    })
    return SelectPersonArr
  } 
}
